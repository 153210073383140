<template>
    <div>
        <CompModal isFull class="jointly-info-modal" ref="comp_modal" title="辖区共建审核" @on-close="onCloseModal">
            <template #head-operate>
                <Input
                    v-if="step === 1 && !customizeMechanism"
                    search
                    enter-button
                    placeholder="机构名称"
                    style="width: 300px; top: 0; margin-right: 15px"
                    @on-search="onSearchOrg"
                    @on-clear="onSearchOrg('')"
                />
                <div v-if="step === 1" class="modal-operate-btn" @click="onDisplayAddOrg">一键新增机构</div>
            </template>

            <div class="content-box">
                <div class="step-box">
                    <Steps :current="step">
                        <Step title="完善信息" content="完善商家入驻信息"></Step>
                        <Step title="关联机构" content="关联商家对应机构"></Step>
                        <Step title="详情页配置" content="辖区共建详情页布局配置"></Step>
                    </Steps>
                </div>
                <div class="step-content">
                    <div class="container jointly-info-form" v-show="step === 0">
                        <div class="menu">
                            <Menu :active-name="showMenu" theme="light" @on-select="activeMenu">
                                <MenuItem name="basicInfo">
                                    <Icon type="ios-information-circle" />
                                    基本信息
                                </MenuItem>
                                <MenuItem v-if="source === 'edit'" name="businessHours">
                                    <Icon type="ios-information-circle" />
                                    营业时间
                                </MenuItem>
                                <MenuItem v-if="source === 'edit'" name="settled">
                                    <Icon type="ios-information-circle" />
                                    入驻信息
                                </MenuItem>
                                <MenuItem v-if="source === 'edit'" v-show="online_service === '1'" name="intro">
                                    <Icon type="ios-information-circle" />
                                    简介
                                </MenuItem>
                            </Menu>
                        </div>
                        <div class="content">
                            <Form class="info-form" :label-colon="true" :label-width="100" ref="form">
                                <div v-show="showMenu == 'basicInfo'" style="padding: 20px">
                                    <ElemFormItem width="120px" name="name" title="单位名称" required>
                                        <Input v-model.trim="formData.name" placeholder="请输入单位名称" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="nature" title="单位性质" required>
                                        <Select v-model="formData.nature" style="width: 300px">
                                            <Option value="1">个体</Option>
                                            <Option value="2">企业/公司/法人</Option>
                                            <Option value="3">国企/央企</Option>
                                            <Option value="4">社会组织/社会团体</Option>
                                            <Option value="5">机关/事业单位</Option>
                                            <Option value="6">其他</Option>
                                        </Select>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="zoneCode" title="所属社区" required>
                                        <elem-cascader :orgCode="4401" :value="formData.community" :width="300" @changeCasader="onChangeCasader($event, formData)"></elem-cascader>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="longitude" title="百度经纬度" required>
                                        <Input placeholder="请输入经纬度" v-model.trim="formData.longitude" :maxlength="40" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="addr" title="详细地址">
                                        <Input placeholder="请填写详细地址" v-model.trim="formData.addr" :maxlength="100" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <!-- <ElemFormItem width="120px" name="addr" title="联系地址" required>
                                        <ElemMap
                                            :address="formData.addr"
                                            :location="{ longitude: formData.longitude, latitude: formData.latitude }"
                                            @on-change="
                                                evt => {
                                                    formData.addr = evt.value.address
                                                    formData.longitude = evt.value.location.longitude
                                                    formData.latitude = evt.value.location.latitude
                                                }
                                            "
                                        ></ElemMap>
                                    </ElemFormItem> -->
                                    <ElemFormItem width="120px" name="principal" title="管理人姓名">
                                        <Input v-model.trim="formData.principal" placeholder="请填写安全生产管理人姓名" style="width: 300px"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" name="mobile" title="联系电话">
                                        <Input v-model.trim="formData.mobile" placeholder="请填写联系电话" style="width: 300px" type="text"></Input>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="封面图片">
                                        <elem-upload ref="elem_upload_cover_image" :value="formData.cover_image" @on-change="formData.cover_image = $event.value"></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="介绍图片">
                                        <elem-upload
                                            ref="elem_upload_introduction_image"
                                            :multiple="true"
                                            :value="formData.introduction_image"
                                            @on-change="formData.introduction_image = $event.value"
                                        ></elem-upload>
                                    </ElemFormItem>
                                    <ElemFormItem width="120px" title="基本介绍">
                                        <Input v-model.trim="formData.remark" :rows="4" placeholder="请填写基本介绍" style="width: 300px" type="textarea"></Input>
                                    </ElemFormItem>
                                </div>

                                <div v-if="source === 'edit'" v-show="showMenu == 'businessHours'" style="padding: 20px">
                                    <elem-form-list v-model="formData.businessTimeRos">
                                        <template v-slot:formBox="data">
                                            <ElemFormItem width="120px" title="星期">
                                                <Select v-model="data.item.rule" multiple style="width: 300px">
                                                    <Option v-for="(item, idx) in ['一', '二', '三', '四', '五', '六', '日']" :key="idx" :value="idx + 1 + ''">周{{ item }}</Option>
                                                </Select>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="开始营业时间">
                                                <TimePicker v-model="data.item.startTime" confirm format="HH:mm" placeholder="选择开始营业时间" style="width: 300px"></TimePicker>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="结束营业时间">
                                                <TimePicker v-model="data.item.endTime" confirm format="HH:mm" placeholder="选择结束营业时间" style="width: 300px"></TimePicker>
                                            </ElemFormItem>
                                        </template>
                                    </elem-form-list>
                                </div>

                                <div v-if="source === 'edit'" v-show="showMenu == 'settled'" style="padding: 20px">
                                    <ElemFormItem width="120px" title="提供线上服务" required>
                                        <RadioGroup v-model="online_service">
                                            <Radio label="0">否</Radio>
                                            <Radio label="1">是</Radio>
                                        </RadioGroup>
                                    </ElemFormItem>
                                    <div v-if="online_service === '1'">
                                        <ElemFormItem width="120px" name="type" title="所属类型" required>
                                            <Select filterable v-if="type_list" v-model="formData.type" style="width: 300px">
                                                <Option v-for="(item, idx) in type_list" :key="idx" :value="item.id">{{ item.labelName }}</Option>
                                            </Select>
                                        </ElemFormItem>
                                        <ElemFormItem width="120px" name="companyLabels" title="标签设置" required>
                                            <Input v-model.trim="formData.companyLabelNames" placeholder="请选择标签" readonly style="width: 300px" type="textarea"></Input>
                                            <Button style="margin-left: 10px" type="primary" @click="onOpenSelectLabel()">选择标签</Button>
                                        </ElemFormItem>
                                        <ElemFormItem width="120px" title="提供预约" required>
                                            <RadioGroup v-model="formData.appointmentStatus">
                                                <Radio label="0">否</Radio>
                                                <Radio label="1">是</Radio>
                                            </RadioGroup>
                                        </ElemFormItem>
                                        <ElemFormItem width="120px" title="是否申请商家" required>
                                            <RadioGroup v-model="shopStatus">
                                                <Radio label="0">否</Radio>
                                                <Radio label="1">是</Radio>
                                            </RadioGroup>
                                        </ElemFormItem>
                                        <div v-if="shopStatus === '1'">
                                            <ElemFormItem width="120px" title="支付平台商户ID">
                                                <Input v-model.trim="formData.merchantId" placeholder="请填写支付平台商户ID" style="width: 300px"></Input>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="电子邮件">
                                                <Input v-model.trim="formData.email" placeholder="请填写电子邮件" style="width: 300px"></Input>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="idNum" title="身份证号" required>
                                                <Input v-model.trim="formData.idNum" placeholder="请填写身份证号" style="width: 300px"></Input>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="手持身份证照片">
                                                <elem-upload ref="elem_upload_id_card_handheld" :value="formData.id_card_handheld" @on-change="formData.id_card_handheld = $event.value"></elem-upload>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="id_card_front" title="身份证正面" required>
                                                <elem-upload ref="elem_upload_id_card_front" :value="formData.id_card_front" @on-change="formData.id_card_front = $event.value"></elem-upload>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="id_card_reverse" title="身份证反面" required>
                                                <elem-upload ref="elem_upload_id_card_reverse" :value="formData.id_card_reverse" @on-change="formData.id_card_reverse = $event.value"></elem-upload>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="营业执照">
                                                <elem-upload ref="elem_upload_business_license" :value="formData.business_license" @on-change="formData.business_license = $event.value"></elem-upload>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="诚信经营承诺函/服务协议">
                                                <elem-upload
                                                    ref="elem_upload_letter_commitment"
                                                    :value="formData.letter_commitment"
                                                    @on-change="formData.letter_commitment = $event.value"
                                                ></elem-upload>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="银行开户名">
                                                <Input v-model.trim="formData.bankAdminName" placeholder="请填写银行开户名" style="width: 300px"></Input>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="个人银行账号">
                                                <Input v-model.trim="formData.bankAccount" placeholder="请填写个人银行账号" style="width: 300px"></Input>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="开户支行名称">
                                                <Input v-model.trim="formData.bankBranchName" placeholder="请填写开户银行支行名称" style="width: 300px"></Input>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" title="支行联行号">
                                                <Input v-model.trim="formData.bankBranchNum" placeholder="请填写支行联行号" style="width: 300px"></Input>
                                            </ElemFormItem>

                                            <div class="title-box">审核信息</div>
                                            <ElemFormItem width="120px" name="settlementType" title="结算类型" required>
                                                <Select v-model="formData.settlementType" style="width: 300px">
                                                    <Option v-for="(item, idx) in settlementTypes" :key="idx" :value="item.dictKey">{{ item.dictValue }}</Option>
                                                </Select>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="platformUseFee" title="平台使用费用" required>
                                                <Input v-model.trim="formData.platformUseFee" placeholder="请填写平台使用费用" style="width: 300px" type="number"></Input>
                                                <span style="margin-left: 10px">元</span>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="margin" title="商家保证金" required>
                                                <Input v-model.trim="formData.margin" placeholder="请填写商家保证金" style="width: 300px" type="number"></Input>
                                                <span style="margin-left: 10px">元</span>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="dividedScale" title="分成比例" required>
                                                <Input v-model.trim="formData.dividedScale" placeholder="请填写分成比例" style="width: 300px" type="number"></Input>
                                                <span style="margin-left: 10px">%</span>
                                            </ElemFormItem>
                                            <ElemFormItem width="120px" name="status" title="审核结果" required>
                                                <Select v-model="formData.status" style="width: 300px">
                                                    <Option value="1">未审核</Option>
                                                    <Option value="2">审核通过</Option>
                                                    <Option value="3">未通过</Option>
                                                </Select>
                                            </ElemFormItem>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="showMenu == 'intro'" style="height: 100%; width: 100%; overflow: hidden">
                                    <iframe :id="ueditor_id" name="ueditor" src="/mobileeditor/#/index?titlename=简介内容" style="width: 100%; height: 100%; border: 0px"></iframe>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <div class="org-table" v-show="step === 1">
                        <ElemTable ref="org_table" :columns="orgTableColumns" :form-component="mechanismForm" :table-api="orgTableApi" height="100%"></ElemTable>
                        <div class="customize-org" v-if="customizeMechanism">
                            <p class="name">已选自定新增机构：{{ customizeMechanism.autoBuildOrgDetailRo.orgName }}</p>
                            <p class="btns-box">
                                <Button class="btn" @click="onClearCustomizeMechanism">选择已有机构</Button>
                                <Button class="btn" @click="onDisplayAddOrg">新增其他机构</Button>
                            </p>
                        </div>
                    </div>

                    <div class="jointly-detail-box container" v-show="step === 2">
                        <div class="content">
                            <Form :model="detailFormData" :label-colon="true" :label-width="100" :rules="formDataValidate" ref="detail_form">
                                <FormItem class="form-item" label="详情页类型" prop="linkType">
                                    <RadioGroup v-model="detailFormData.linkType">
                                        <Radio label="1">通用</Radio>
                                        <Radio label="2">自定义页面</Radio>
                                        <Radio label="3">小程序</Radio>
                                    </RadioGroup>
                                </FormItem>

                                <!-- 类型：通用 -->
                                <block v-show="detailFormData.linkType === '1'">
                                    <FormItem label="默认进入页面">
                                        <Select v-model="linkJson.defaultPage" style="width: 300px">
                                            <Option value="home">首页</Option>
                                            <Option value="commodity">商品</Option>
                                            <!-- <Option value="comment">评价</Option> -->
                                            <Option value="reserve">预约</Option>
                                            <Option value="information">资讯</Option>
                                            <Option value="activity">活动</Option>
                                        </Select>
                                    </FormItem>

                                    <div class="elem-detail-title-box">
                                        <p class="serial-number">1</p>
                                        <p class="name">首页</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.home.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width: 300px" v-model.trim="linkJson.home.rename" placeholder="请填写重命名"></Input>
                                    </FormItem>

                                    <div class="elem-detail-title-box">
                                        <p class="serial-number">2</p>
                                        <p class="name">商品</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.commodity.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width: 300px" v-model.trim="linkJson.commodity.rename" placeholder="请填写重命名"></Input>
                                    </FormItem>

                                    <FormItem label="关联店铺编码">
                                        <Input style="width: 300px" v-model.trim="linkJson.commodity.column" placeholder="请填写关联店铺编码"></Input>
                                    </FormItem>

                                    <!-- <div class="elem-detail-title-box">
                                        <p class="serial-number">3</p>
                                        <p class="name">评价</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.comment.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width:300px" v-model.trim="linkJson.comment.rename" placeholder="请填写重命名"></Input>
                                    </FormItem> -->

                                    <div class="elem-detail-title-box">
                                        <p class="serial-number">3</p>
                                        <p class="name">预约</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.reserve.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width: 300px" v-model.trim="linkJson.reserve.rename" placeholder="请填写重命名"></Input>
                                    </FormItem>

                                    <FormItem label="关联栏目">
                                        <Input
                                            style="width: 300px"
                                            type="textarea"
                                            v-model="linkJson.reserve.column.columnName"
                                            :value="linkJson.reserve.column ? linkJson.reserve.column.columnName : ''"
                                            placeholder="请填写栏目名称"
                                        ></Input>
                                        <Input
                                            style="width: 300px"
                                            type="textarea"
                                            v-model="linkJson.reserve.column.columnCode"
                                            :value="linkJson.reserve.column ? linkJson.reserve.column.columnCode : ''"
                                            placeholder="请填写栏目编码"
                                        ></Input>
                                        <!-- <Input
                                            style="width: 300px"
                                            type="textarea"
                                            
                                            :value="linkJson.reserve.column ? linkJson.reserve.column.columnName : ''"
                                            placeholder="请选择关联栏目"
                                        ></Input> -->
                                        <!-- <Button type="primary" style="margin-left: 10px" @click="onOpenSelectColumn('reserve')">选择栏目</Button> -->
                                    </FormItem>

                                    <div class="elem-detail-title-box">
                                        <p class="serial-number">4</p>
                                        <p class="name">资讯</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.information.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width: 300px" v-model.trim="linkJson.information.rename" placeholder="请填写重命名"></Input>
                                    </FormItem>

                                    <FormItem label="关联栏目">
                                        <Input
                                            style="width: 300px"
                                            type="textarea"
                                            :value="linkJson.information.column ? linkJson.information.column.columnName : ''"
                                            v-model.trim="linkJson.information.column.columnName"
                                            placeholder="请填写栏目名称"
                                        ></Input>
                                        <Input
                                            style="width: 300px"
                                            type="textarea"
                                            v-model="linkJson.information.column.columnCode"
                                            :value="linkJson.information.column ? linkJson.information.column.columnCode : ''"
                                            placeholder="请填写栏目编码"
                                        ></Input>
                                        <!-- <Button type="primary" style="margin-left: 10px" @click="onOpenSelectColumn('information')">选择栏目</Button> -->
                                    </FormItem>

                                    <div class="elem-detail-title-box">
                                        <p class="serial-number">5</p>
                                        <p class="name">活动</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.activity.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width: 300px" v-model.trim="linkJson.activity.rename" placeholder="请填写重命名"></Input>
                                    </FormItem>

                                    <FormItem label="关联栏目">
                                        <Input
                                            style="width: 300px"
                                            type="textarea"
                                            v-model="linkJson.activity.column.columnName"
                                            :value="linkJson.activity.column ? linkJson.activity.column.columnName : ''"
                                            placeholder="请填写栏目名称"
                                        ></Input>
                                        <Input
                                            style="width: 300px"
                                            type="textarea"
                                            v-model="linkJson.activity.column.columnCode"
                                            :value="linkJson.activity.column ? linkJson.activity.column.columnCode : ''"
                                            placeholder="请填写栏目编码"
                                        ></Input>
                                        <!-- <Button type="primary" style="margin-left: 10px" @click="onOpenSelectColumn('activity')">选择栏目</Button> -->
                                    </FormItem>

                                    <div class="elem-detail-title-box">
                                        <p class="serial-number">6</p>
                                        <p class="name">公告</p>
                                    </div>

                                    <FormItem label="启用">
                                        <i-switch size="large" v-model="linkJson.announcement.isEnable">
                                            <span slot="1">启用</span>
                                            <span slot="0">禁用</span>
                                        </i-switch>
                                    </FormItem>

                                    <FormItem label="重命名">
                                        <Input style="width: 300px" v-model.trim="linkJson.announcement.rename" placeholder="请填写重命名"></Input>
                                    </FormItem>

                                    <FormItem label="公告内容">
                                        <Input style="width: 300px" type="textarea" v-model.trim="linkJson.announcement.content" placeholder="请填写公告内容"></Input>
                                    </FormItem>
                                </block>

                                <!-- 类型：自定义页面 -->
                                <block v-show="formData.linkType === '2'">
                                    <FormItem label="跳转链接" prop="columnId">
                                        <Input style="width: 300px" v-model.trim="linkJson.link" placeholder="请填写跳转链接"></Input>
                                    </FormItem>
                                </block>

                                <!-- 类型：小程序 -->
                                <block v-show="formData.linkType === '3'">
                                    <FormItem label="页面地址" prop="page">
                                        <Input style="width: 300px" v-model.trim="linkJson.pageAddr" placeholder="请填写页面地址"></Input>
                                    </FormItem>
                                    <FormItem label="APPID" prop="appid">
                                        <Input style="width: 300px" v-model.trim="linkJson.appId" placeholder="请填写APPID"></Input>
                                    </FormItem>
                                </block>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn" v-if="step === 0" @click="evt.close()">取消</div>
                    <div class="operate-btn" v-else @click="step--">上一步</div>
                    <div class="operate-btn highlight" @click="onNextStep">{{ step === 2 ? "提交" : "下一步" }}</div>
                </div>
            </template>
        </CompModal>
        <comp-form
            ref="comp_form"
            :data-before="processData"
            :fullscreen="true"
            :submit-before="onSubmitBefore"
            class="jointly-comp-form"
            detail-api="/gateway/api/sycompany/pc/company/getCompanyInfoById"
            id-key="companyId"
            submit-api="/gateway/api/sycompany/pc/company/saveCompany"
            title="辖区共建"
            @on-close="onCloseForm"
        >
            <template v-slot:form-box="data"></template>
        </comp-form>

        <comp-label ref="comp_label" @on-submit="onSubmitLabel"></comp-label>

        <MechanismFormStep ref="mechanism_form_step" @on-submit="onChangeCustomizeMechanism"></MechanismFormStep>

        <DataForm ref="data_form" @on-submit="$emit('on-submit', $event)"></DataForm>
    </div>
</template>

<script>
import CompModal from "../../residentdatabase/components/CompModal.vue"
import ElemTable from "../../communitymobilization/components/ElemTable.vue"
import MechanismFormStep from "./childrens/mechanism_form_step.vue"
import DataForm from "./form.vue"

import CompLabel from "./comp-label.vue"
import ElemUpload from "../components/elem-upload.vue"
import ElemFormList from "../components/elem-form-list.vue"
import ElemFormItem from "../components/elem-form-item.vue"
import ElemCascader from "../components/elem-cascader.vue"
import ElemMap from "../components/ElemMap.vue"
import CompForm from "../components/CompForm.vue"

import Headway from "../utils/headway"

import RequestPage from "../utils/page"
import Utils from "../utils/utils"
import Request from "../utils/request"

export default {
    components: { CompModal, DataForm, ElemTable, MechanismFormStep, CompForm, CompLabel, ElemUpload, ElemCascader, ElemFormList, ElemFormItem, ElemMap },

    data() {
        return {
            companyId: null,
            // 类型列表
            type_list: null,
            // 富文本组件 ID
            ueditor_id: this.$core.randomString(),
            // 是否开启线上服务
            online_service: "1",
            // 是否申请商家
            shopStatus: "1",
            // 菜单
            showMenu: "basicInfo",
            // 来源
            source: null,
            // 结算类型
            settlementTypes: null,
            // 表单数据
            formData: {},
            // 当前步骤
            step: 0,

            // 已选关联表单项
            selectOrgRadio: null,
            // 关联机构表格接口
            orgTableApi: `/gateway/apps/org/org/pc/organization/selectOrganizationPage?custGlobalId=${this.$core.getUserInfo().custGlobalId}&orgCode=${
                this.$core.getUserInfo().orgCode
            }&orgType=1&oemCode=${this.$core.getUserInfo().oemCode}&parentId=0`,
            // 关联机构表格项
            orgTableColumns: [
                {
                    title: "单选框",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("Radio", {
                            style: {
                                "margin-right": 0,
                            },
                            props: {
                                value: this.selectOrgRadio?.orgId === params.row.orgId,
                            },
                            on: {
                                "on-change": _ => {
                                    this.selectOrgRadio = params.row
                                },
                            },
                        })
                    },
                },
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "机构组织编码",
                    key: "orgCode",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "所属组织纬度",
                    key: "dimensionName",
                    minWidth: 200,
                    align: "center",
                },
            ],

            // 详情页配置链接表单数据
            linkJson: {
                home: {},
                commodity: {},
                comment: {},
                reserve: {},
                information: {},
                activity: {},
                announcement: {},
            },
            // 详情页配置表单数据
            detailFormData: {
                linkType: "",
            },

            // 自定义新增机构
            customizeMechanism: null,
        }
    },

    async created() {
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)
    },

    methods: {
        async open(id) {
            this.onCloseForm()
            this.companyId = id
            this.showMenu = "basicInfo"
            this.source = id ? "edit" : "create"

            if (!this.type_list) {
                this.type_list = await this.getAllType()
            }

            if (!this.settlementTypes) {
                this.getAllSettlementType()
            }

            if (id) {
                this.getDetail(id)
                this.$refs.comp_modal.display()
            } else {
                this.$refs.data_form.open()
            }
        },

        getDetail(id) {
            Request.get("/gateway/api/sycompany/pc/company/getCompanyInfoById", {
                companyId: id,
            }).then(res => {
                this.formData = this.processData(res)
            })
        },

        onCloseForm() {
            this.linkJson = {
                home: {},
                commodity: {},
                comment: {},
                reserve: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                information: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                activity: {
                    column: {
                        columnName: "",
                        columnCode: "",
                    },
                },
                announcement: {},
            }
            document.getElementById(this.ueditor_id)?.contentWindow?.setContent("")
        },

        /**
         * 打开标签选择弹窗
         */
        onOpenSelectLabel() {
            this.$refs.comp_label.open(this.labelCodes)
        },

        /**
         * 处理数据
         */
        processData(data) {
            var res = {}

            for (let i = 0, fs = data?.companyFileVos; i < fs?.length; i++) {
                const f = fs[i]

                if (!f || !f.fileUrl) {
                    continue
                }

                f.url = decodeURIComponent(f.fileUrl)

                switch (f.businessType) {
                    case "1": // 营业执照
                        res.business_license = f
                        break
                    case "2": // 封面图片
                        res.cover_image = f
                        break
                    case "5": // 手持身份证照
                        res.id_card_handheld = f
                        break
                    case "6": // 身份证正面
                        res.id_card_front = f
                        break
                    case "7": // 身份证反面
                        res.id_card_reverse = f
                        break
                    case "8": // 介绍图片
                        if (!res.introduction_image) {
                            res.introduction_image = []
                        }
                        res.introduction_image.push(f)
                    case "9": // 诚信经营承诺函/服务协议
                        res.letter_commitment = f
                        break
                }
            }

            // 营业时间
            res.businessTimeRos = data.businessTimeRos
                ? Utils.each(data.businessTimeRos, e => {
                      // 拆分周规则的数组
                      e.rule = e.rule.split(",")
                  })
                : []

            this.shopStatus = data.companyInfoRoV2.shopStatus

            // 支付平台商户ID
            res.merchantId = data.merchantId

            // 拷贝对象
            Utils.copyObjcetByKeys(res, data.companyInfoRoV2)
            Utils.copyObjcetByKeys(res, data.companyArchivesRo)

            // 写入富文本内容
            data.companyInfoRoV2.introduce &&
                new Headway(document.getElementById(this.ueditor_id).contentWindow, "setContent").on().then(f => {
                    f(data.companyInfoRoV2.introduce)
                })

            // 写入个人信息
            Utils.each(data.companyChargeVos, v => {
                if (v.chargeType == "2") {
                    Utils.copyObjcetByKeys(res, v, "mobile", "idNum")
                    // 生产管理人
                    res.principal = v.name
                }
            })

            // 标签
            const codes = [],
                ids = [],
                names = []

            // 处理标签数据
            Utils.each(data.labelVoList, v => {
                if (!v) return

                switch (v.labelType) {
                    case "1":
                        res.type = v.id
                        break
                    case "2":
                    case "3":
                        codes.push(v.labelCode)
                        ids.push(v.id)
                        names.push(v.labelName)
                        break
                }
            })

            // 标签
            this.labelCodes = codes
            res.companyLabels = ids
            res.companyLabelNames = names.join(", ")

            // 处理社区所属
            res.community = []

            Utils.each(["zoneCode", "streetCode", "communityCode"], k => {
                if (!data.companyInfoRoV2[k]) {
                    return "break"
                }
                res.community.push(data.companyInfoRoV2[k])
            })

            console.log("查看数据", data)
            if (data.companyInfoRoV2.longitude && data.companyInfoRoV2.latitude && data.companyInfoRoV2.longitude != "" && data.companyInfoRoV2.latitude != "") {
                console.log("是否存在", data.longitude)
                res.longitude = data.companyInfoRoV2.longitude + "," + data.companyInfoRoV2.latitude
                console.log("存在了", res.longitude)
            }

            return res
        },

        onSubmitLabel(evt) {
            const es = evt.value.entitys
            const ids = []
            const names = []
            const codes = []

            for (let i = 0; i < es.length; i++) {
                let v = es[i]

                ids.push(v.id)
                names.push(v.labelName)
                codes.push(v.labelCode)
            }

            this.labelCodes = codes
            this.$set(this.formData, "companyLabels", ids)
            this.$set(this.formData, "companyLabelNames", names.join(", "))
        },

        /**
         * 获取所有标签
         */
        getAllType() {
            return new Promise(resolve => {
                const page = new RequestPage(`/gateway/api/sycompany/pc/label/getLabelByPage?oemCode=${this.$core.getUserInfo().oemCode}&labelType=1`, {
                    onChange: res => {
                        if (page.isLoaded()) {
                            for (let i = 0; i < res.length; i++) {
                                const e = res[i]
                                e.key = e.labelCode
                            }

                            resolve(res)
                        } else {
                            page.load()
                        }
                    },
                })
            })
        },

        getAllSettlementType() {
            return this.$get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "BILLING_TYPE",
                appCode: "",
            }).then(res => {
                this.settlementTypes = res.dataList
            })
        },

        activeMenu(menu) {
            this.showMenu = menu
        },

        /**
         * 提交表单的前置事件
         */
        onSubmitBefore(data) {
            const res = {
                oemCode: parent.vue.oemInfo.oemCode,
                companyId: this.companyId,
            }
            const iol = this.online_service === "1"

            var businessTimeRos = []

            // 营业时间
            Utils.each(data.businessTimeRos, v => {
                if (v.rule && v.rule.length > 0 && v.startTime && v.endTime) {
                    // 合并周规则的数组
                    var rule = v.rule instanceof Array ? v.rule.join(",") : v.rule
                    // 添加到数组
                    businessTimeRos.push({
                        ...v,
                        rule,
                    })
                }
            })

            res.businessTimeRos = businessTimeRos

            // 获取标签
            const cls = data.companyLabels || []
            // 是否存在类型标签
            if (data.type) {
                cls.push(data.type)
            }

            res.companyInfoRoV2 = {
                companyLabels: cls,
                startDate: data.startDate || new Date(),
                businessStatus: data.businessStatus || "2",
                shopStatus: this.shopStatus,
                remark: data.remark,
            }

            Utils.copyObjcetByKeys(
                res.companyInfoRoV2,
                data,
                "name",
                "addr",
                "nature",
                "zoneCode",
                "streetCode",
                "communityCode",
                "settlementType",
                "platformUseFee",
                "margin",
                "dividedScale",
                "status",
                "longitude",
                "latitude"
            )

            // 个人信息
            res.companyChargeVos = [
                {
                    chargeType: "2",
                    idNum: iol && this.shopStatus == "1" ? data.idNum : null,
                    mobile: data.mobile,
                    name: data.principal,
                },
            ]

            // 文件资源
            var fs = []

            // 封面图片
            if (data.cover_image) {
                fs.push({
                    businessType: 2,
                    fileId: data.cover_image.fileId,
                    fileUrl: data.cover_image.url,
                })
            }

            // 介绍图片，多图模式
            if (data.introduction_image) {
                data.introduction_image.forEach(v => {
                    fs.push({
                        businessType: 8,
                        fileId: v.fileId,
                        fileUrl: v.url,
                    })
                })
            }

            // 是否开启线上模式
            if (iol) {
                // 拷贝对象值
                Utils.copyObjcetByKeys(res.companyInfoRoV2, data, "appointmentStatus", "shopStatus")
                res.companyInfoRoV2.shopStatus = this.shopStatus

                // 是否申请商家
                if (this.shopStatus == "1") {
                    // 支付平台商户ID
                    res.merchantId = data.merchantId

                    res.companyArchivesRo = {
                        email: data.email,
                    }

                    // 上传图片
                    data.business_license &&
                        fs.push({
                            businessType: 1,
                            fileId: data.business_license.fileId,
                            fileUrl: data.business_license.url,
                        })
                    data.id_card_handheld &&
                        fs.push({
                            businessType: 5,
                            fileId: data.id_card_handheld.fileId,
                            fileUrl: data.id_card_handheld.url,
                        })
                    data.id_card_front &&
                        fs.push({
                            businessType: 6,
                            fileId: data.id_card_front.fileId,
                            fileUrl: data.id_card_front.url,
                        })
                    data.id_card_reverse &&
                        fs.push({
                            businessType: 7,
                            fileId: data.id_card_reverse.fileId,
                            fileUrl: data.id_card_reverse.url,
                        })
                    data.letter_commitment &&
                        fs.push({
                            businessType: 9,
                            fileId: data.letter_commitment.fileId,
                            fileUrl: data.letter_commitment.url,
                        })

                    Utils.copyObjcetByKeys(res.companyArchivesRo, data, "bankAdminName", "bankAccount", "bankBranchName", "bankBranchNum")

                    // if (res.companyArchivesRo && Object.keys(res.companyArchivesRo)) {
                    // }
                }

                // 获取富文本简介内容
                res.companyInfoRoV2.introduce = document.getElementById(this.ueditor_id).contentWindow.getContent()
            }

            res.companyFileVos = fs
            if (data.longitude && data.longitude != "" && data.longitude.indexOf(",") > -1) {
                let list = data.longitude.split(",")
                res.companyInfoRoV2.longitude = list[0]
                res.companyInfoRoV2.latitude = list[1]
            }
            return res
        },

        /**
         * 监听社区改变事件
         */
        onChangeCasader(evt) {
            Utils.each(evt, (v, i) => {
                this.$set(this.formData, ["zoneCode", "streetCode", "communityCode"][i], v)
            })
        },

        getAllFormItem(formData, children = [], res = {}) {
            for (let i = 0; i < children.length; i++) {
                let n = children[i]

                let tag = n.$vnode?.tag

                if (tag.indexOf("ElemMenuItem") > -1) {
                    if (n.condition && !n.condition(formData)) continue
                } else if (tag.indexOf("ElemFormItem") > -1) {
                    if (!n.name) continue

                    res[n.name] = {
                        value: n,
                    }
                } else if (tag.indexOf("ElemFormList") > -1) {
                    if (!n.name) continue
                    // 获取所有 list 组件下的排列
                    res[n.name] = {
                        type: "LIST",
                        value: this.getAllFormItem(formData, n.$children),
                    }
                    // 不继续遍历
                    continue
                }

                if (n.$children?.length > 0) {
                    this.getAllFormItem(formData, n.$children, res)
                }
            }

            return res
        },

        /**
         * 提交表单事件
         */
        getFormData() {
            return new Promise(async resolve => {
                var data = { ...this.formData, ...this.watch_data }

                // 获取所有表单组件项
                const cs = this.getAllFormItem(data, this.$refs.form.$children)

                // 循环校验表单
                for (let i = 0, ks = Object.keys(cs); i < ks.length; i++) {
                    var k = ks[i]
                    var c = cs[k]

                    let name = c.value.name || k

                    if (!name === "undefined") {
                        continue
                    }

                    if ("string" === typeof name) {
                        name = [name]
                    }

                    for (let n_i = 0; n_i < name.length; n_i++) {
                        const n = name[n_i]

                        // 列表表单数据结构
                        if (c.type === "LIST") {
                            const list = c.value

                            // 循环数据源数组
                            for (let v_i = 0, d = data[n]; v_i < d?.length; v_i++) {
                                var d_v = d[v_i]

                                // 循环组件配置
                                for (let c_i = 0, c_ks = Object.keys(list); c_i < c_ks.length; c_i++) {
                                    var c_k = c_ks[c_i]
                                    var c_v = list[c_k].value
                                    var d_v_i = d_v[c_k]

                                    // 当前组件处于隐藏状态，移除数据
                                    if (!c_v.display) {
                                        delete d_v[c_k]
                                        continue
                                    }

                                    // 校验必选
                                    if (c_v.rq && Utils.isBlank(d_v_i)) {
                                        return this.$Message.error({
                                            content: `${c_v.title ?? c_v.name} 不能为空`,
                                            background: true,
                                        })
                                    }

                                    // 校验内容
                                    if (c_v.verify && d_v_i && !Utils.verify(c_v.verify, d_v_i)) {
                                        return this.$Message.error({
                                            content: `${c_v.title ?? c_v.name} 格式不正确`,
                                            background: true,
                                        })
                                    }
                                }
                            }
                        } else {
                            // 当前组件处于隐藏状态，移除数据
                            if (!c.value.display) {
                                delete data[n]
                                continue
                            }

                            // 校验必选
                            if (c.value.rq && Utils.isBlank(data[n])) {
                                return this.$Message.error({
                                    content: `${c.value.title ?? n} 不能为空`,
                                    background: true,
                                })
                            }

                            // 校验内容
                            if (c.value.verify && data[n] && !Utils.verify(c.value.verify, data[n])) {
                                return this.$Message.error({
                                    content: `${c.value.title ?? n} 格式不正确`,
                                    background: true,
                                })
                            }
                        }
                    }
                }

                var cb = this.onSubmitBefore(data, !!this.detailId, this.extra)

                if (typeof cb !== "undefined") {
                    // 判断是否为异步
                    if (cb instanceof Promise) {
                        cb = await cb
                    }

                    if (cb === false) return

                    // 使用前置事件返回的数据
                    data = cb
                }

                // 存在 ID 时为修改模式
                if (this.detailId) {
                    data[this.idKey] = this.detailId
                }

                resolve(data)
            })
        },

        onSubmit() {
            const data = {
                ...this.infoFormdata,
                companyServiceRo: this.detailData,
            }

            if (this.selectOrgRadio?.orgCode) {
                data.orgCode = this.selectOrgRadio.orgCode
            } else {
                Object.assign(data, this.customizeMechanism)
            }

            Request.post("/gateway/api/sycompany/pc/company/saveCompany", data, {
                json: true,
            }).then(() => {
                this.$Message.success({
                    content: "提交成功",
                    background: true,
                })

                this.$refs.comp_modal.close()

                this.$emit("on-submit")
            })
        },

        /**
         * 下一步
         */
        async onNextStep() {
            switch (this.step) {
                case 0:
                    this.infoFormdata = await this.getFormData()
                    break
                case 1:
                    if (!this.selectOrgRadio && !this.customizeMechanism) {
                        return this.$Message.error({
                            content: "请选择或创建关联机构",
                            background: true,
                        })
                    }
                    break
                case 2:
                    this.detailData = await this.getDetailConfig()
                    return this.onSubmit()
            }

            this.step++
        },

        /**
         * 搜索机构
         */
        onSearchOrg(evt) {
            this.$refs.org_table.search({
                orgName: evt,
            })
        },

        /**
         * 获取详情页配置数据
         */
        async getDetailConfig() {
            return new Promise(resolve => {
                const fd = this.detailFormData

                if (!fd.linkType) {
                    return this.$Message.error({
                        content: "请选择详情页类型",
                        background: true,
                    })
                }

                const sl = this.linkJson
                const pd = {
                    linkType: fd.linkType,
                    companyId: this.detailId,
                }

                switch (fd.linkType) {
                    case "1":
                        pd.linkJson = JSON.stringify(sl)
                        break
                    case "2":
                        pd.linkJson = JSON.stringify({
                            link: sl.link,
                        })
                        break
                    case "3":
                        pd.linkJson = JSON.stringify({
                            pageAddr: sl.pageAddr,
                            appId: sl.appId,
                        })
                        break
                }

                resolve(pd)
            })
        },

        /**
         * 自定新增机构
         */
        onChangeCustomizeMechanism(evt) {
            this.customizeMechanism = evt.value
        },

        /**
         * 清除自定义新增机构数据
         */
        onClearCustomizeMechanism() {
            this.$Modal.confirm({
                title: "提示",
                content: "该操作将清除已新增机构数据，是否继续？",
                onOk: () => {
                    this.customizeMechanism = null
                },
            })
        },

        /**
         * 监听关闭弹窗初始化数据
         */
        onCloseModal() {
            this.formData = {}
            this.step = 0
            this.selectOrgRadio = null
            this.customizeMechanism = null
            this.linkJson = {
                home: {},
                commodity: {},
                comment: {},
                reserve: {},
                information: {},
                activity: {},
                announcement: {},
            }
            this.detailFormData = {
                linkType: "",
            }
        },

        /**
         * 新增机构
         */
        onDisplayAddOrg() {
            const fd = this.formData

            this.$refs.mechanism_form_step.display({
                orgName: fd.name,
                addr: {
                    cityCode: "4401",
                    countyCode: fd.communityCode.substring(0, 6),
                    streetCode: fd.streetCode.substring(0, 9),
                    communityCode: fd.communityCode,
                    addr: fd.addr,
                    longitude: fd.longitude,
                    latitude: fd.latitude,
                },
                qualification: {
                    tel: fd.mobile,
                },
            })
        },
    },
}
</script>

<style lang="less">
.jointly-info-modal {
    .modal-operate-btn {
        cursor: pointer;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2faaf7;
        color: #fff;
        border-radius: 4px;
        height: 32px;
    }

    .content-box {
        height: 100%;
        display: flex;
        flex-direction: column;

        .step-box {
            padding: 20px;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #e3e3e3;

            .ivu-steps {
                max-width: 700px;
            }
        }

        .step-content {
            flex: 1;
            overflow: auto;

            .menu {
                .ivu-menu {
                    height: 100%;
                }
            }

            .org-table {
                position: relative;
                height: 100%;

                .elem-table {
                    border-radius: 0;
                    border: 0;
                }

                .customize-org {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba(255, 255, 255, 0.95);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    z-index: 999;

                    .name {
                        font-size: 16px;
                        color: #333;
                        margin-bottom: 10px;
                    }

                    .btns-box {
                        display: flex;
                        align-items: center;

                        .btn {
                            margin: 0 10px;
                        }
                    }
                }
            }

            .jointly-detail-box {
                padding: 20px;
                height: auto !important;
            }

            .info-form {
                height: 100%;
            }
        }
    }

    .jointly-info-form {
        .title-box {
            margin: 30px 0 10px 0;
            font-size: 22px;
            font-weight: bold;
            color: #2faaf7;
        }
    }

    .securityDiv {
        border-width: 0px;
        margin-left: 20px;
        width: 72px;
        height: 24px;
        display: flex;
        font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC", sans-serif;
        font-weight: 650;
        font-style: normal;
        color: #10bc92;
    }

    .container {
        display: flex;
        justify-content: space-between;
        height: 100% !important;

        /deep/ .menu {
            height: 100%;

            .ivu-menu {
                height: 100%;
                width: 220px !important;
            }
        }

        .content {
            overflow: auto;
            flex: 1;
            height: 100% !important;
            padding-top: 0 !important;
        }
    }

    /deep/ .validate {
        &::before {
            content: "*";
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            font-family: SimSun;
            font-size: 14px;
            color: #ed4014;
        }
    }

    /deep/ #modal_contentarea {
        overflow: inherit !important;
    }
}

.jointly-comp-form {
    .content-box {
        padding: 0 !important;
        height: 100%;

        form {
            height: 100%;
        }
    }
}
</style>
